.tradition {
    width: 75%;
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #631d1a;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
}

.btn-accept {
    background-color: #000;
    color: #fff !important;
    border: none;
    border-radius: 5px;
    outline: none !important;
}
@media screen and (min-width: 320px) and (max-width: 639px) {
    .btn-accept {
        font-size: 0.8em;
        padding: 5px 10px;
    }
}
@media screen and (min-width: 640px) {
    .btn-accept {
        font-size: 1.2em;
        padding: 5px 20px;
    }
}

#cookiesbar {
    position: fixed;
    width: 100%;    
    align-self: center;
    align-items: center;
    background: #fff;
    z-index: 9999;
    bottom: 0;
    left: 0;
    text-align: center;
    opacity: 0.8;
    box-shadow: 0px -2px 2px rgb(0 0 0 / 10%);
}
#cookiesbar div {
    padding: 20px 10px;
}
#cookiesbar a {
    color: #337ab7 !important;
    text-decoration: underline !important;

}