.sweet-alert {
    overflow: none !important;
}
#custom-sweet-alert .sweet-alert {
    position: relative !important;
    box-shadow: 1px 4px 14px 7px rgba(0,0,0,0.16) !important;
    border-radius: 30px !important;
    padding: 0 !important;
    height: 800px;
    width: 1140px !important;
    overflow: none  !important;
}

#custom-sweet-alert .sweet-alert  div:nth-child(1){
    width: 100% !important;
    min-height: 240px !important;
    align-self: flex-start;
    position: absolute;
    z-index: 1 !important;
    bottom: 101px;
    overflow: none !important;
}


#custom-sweet-alert .sweet-alert  h2 {
    padding: 1rem !important;
    position:absolute !important;
    z-index: 0 !important;
    width: 100% !important;
    top: 25px !important;
    font-family: Kanit;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #44B36F;
}

#custom-sweet-alert .sweet-alert div:nth-child(3) {
    /* padding: 1rem !important; */
    text-align: left !important;
}

#custom-sweet-alert .sweet-alert p  {
    margin-bottom: 1rem !important;
    display: flex;
    flex-direction: column-reverse !important;
}

#custom-sweet-alert .sweet-alert a  {
    width: 300px!important;
    margin-right: 0!important;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -20px !important;
    z-index: 999;
    display: flex;
    background: linear-gradient(0deg, #BE4E00 0%, #F3CB96 100%);
    border: 5px solid #F3B46A !important;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 40px;
}


@media (max-width: 991.98px) {

    #custom-sweet-alert .sweet-alert {
        box-shadow: 1px 4px 14px 7px rgba(0,0,0,0.16) !important;
        border-radius: 30px !important;
        padding: 0 !important;
        width: 100% !important;
    }    
    #custom-sweet-alert .sweet-alert div:nth-child(1) {
        
        width: 100% !important;
        align-self: flex-start;
        position: relative !important;
        top: 85px;
        overflow: none !important;
    }
    
        #custom-sweet-alert .sweet-alert  h2 {
            padding: 1rem !important;
            position:absolute !important;
            z-index: 0 !important;
            width: 100% !important;
            top: 0px !important;
            font-family: Kanit;
            font-style: normal;
            font-weight: 600;
            font-size: 20px !important;
            line-height: 60px;
            display: flex;
            align-items: center;
            text-align: center;
            display: flex;
            justify-content: center;
            color: #44B36F;
            top: -15px !important;
        }
    }
    
    .border-content-popup {
        width: 85%;
        display: block;
        margin: 0 auto !important;
        height: auto;
        background: rgba(255, 219, 88, 0.3);
        border-radius: 30px;
        padding:2rem;
        margin-top: 1rem !important;
    }
    @media (max-width: 575.98px) {
            
            #custom-sweet-alert .sweet-alert div:nth-child(1) {
            padding: 0.75rem;
            width: 100% !important;
            align-self: flex-start;
            position: relative !important;
            top: 40px;
            background-repeat: no-repeat !important;
            overflow: none !important;
            /* margin-top: 2rem; */
            margin-bottom: 2rem;
        }
        .border-content-popup {
            width: 100%;
            display: block;
            margin: 0 auto !important;
            height: auto;
            background: rgba(255, 219, 88, 0.3);
            border-radius: 30px;
            padding:0.75rem;
            text-align: left !important;
            margin-top: 2rem !important;
        }

        #custom-sweet-alert .sweet-alert a {
            width: 170px!important;
            margin-right: 0!important;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -32px ​!important;
            display: flex;
            position: absolute;
            background: linear-gradient( 
        0deg
         , #BE4E00 0%, #F3CB96 100%);
            border: 5px solid #F3B46A !important;
            box-sizing: border-box;
            box-shadow: 0px 5px 10px rgb(0 0 0 / 50%);
            border-radius: 40px;
        }
    }

    .text-popup {
        white-space: pre-line;
    }