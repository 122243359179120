@font-face {
  font-family: 'Kanit-SemiBold';
  src: local('Kanit-SemiBold'), url(./assets/fonts-new/Kanit-SemiBold.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Kanit-Light';
  src: local('Kanit-Light'), url(./assets/fonts-new/Kanit-Light.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Kanit-Medium';
  src: local('Kanit-Medium'), url(./assets/fonts-new/Kanit-Medium.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

body {
  /* background: #C43831 url('./assets/images/landing/2023/Grid3.png') center center repeat; */
  /* background: url('./assets/images/landing/2023/Grid-3.png'), linear-gradient(180deg, rgba(194, 0, 0, 1) 0%, rgba(164, 0, 0, 1) 20%, rgba(120, 0, 1, 1) 80%); */
  background: url('./assets/images/2024/bg3.webp');
  background-size: cover;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Kanit-SemiBold !important;
}

a {
  text-decoration: none !important;
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.font-light {
  font-family: 'Kanit-Light' !important;
}

.font-medium {
  font-family: 'Kanit-Medium' !important;
}

.text {
  position: absolute;
}

.text-decoration {
  text-decoration: underline !important;
}

.section-title h1 {
  font-weight: 600;
}

.wrapper-product h4 {
  font-weight: 600;
  margin: 30px 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  letter-spacing: 0px;
}

.nav_list a {
  font-weight: 600 !important;
  border-radius: 0rem !important;
}

.text-right {
  text-align: right;
  vertical-align: top;
}

/* .text-pl{
  padding-left: 5px;
} */
.pt-right-10 {
  padding-left: 10px;
}

.w-60px {
  width: 60px;
}

.w-80 {
  width: 80% !important;
  padding: 10% !important;
}

.w-100px {
  width: 100px;
}

.div-mongkhol-detail {
  margin: auto;
  padding: 10px;
}

.color-yellow {
  color: #FFDB58;
}

.color-red {
  color: #9B0014;
}

.sidebar {
  display: none;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9999;
  padding-left: 6px;
  width: 80px;
  height: 45px;
  background: linear-gradient(90deg, rgba(161, 101, 5, 1) 0%, rgba(219, 165, 4, 1) 20%, rgba(219, 165, 4, 1) 50%, rgba(219, 165, 4, 1) 75%, rgba(161, 101, 5, 1) 100%);
  border-radius: 10px;
  box-shadow: 2px 2px;
}

.menu-sidebar {
  color: white;
  margin-top: 1px;
}

.bg-red {
  /* background: #C43831 url('./assets/images/landing/2023/Grid3.png') center center repeat; */
  /* background: url('./assets/images/landing/2023/Grid-3.png'), linear-gradient(180deg, rgba(194, 0, 0, 1) 0%, rgba(164, 0, 0, 1) 20%, rgba(120, 0, 1, 1) 80%); */
  background: url('./assets/images/landing/2023/Repeat-Grid-1.png');
  background-size: cover;
}


/***** MASTHEAD ****/
.logo {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 100 !important;
}

.logo2 {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 100 !important;
}

.toshop,
.toshop-hover {
  /* position: relative; */
  cursor: pointer !important;
  /* background-image: url('./assets/images/landing/2023/to-shop.png');
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 999; */
}

.section-mg {
  position: inherit;
  margin-top: 5%;
}

.offlineShop-m {
  visibility: hidden;
}

.cover-original {
  position: relative;
  background-image: url('./assets/images/landing/CP-CNY2022_KV-Home-bg2.png');
  height: 900px;
  width: 100%;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 0;
}

.button-1 {
  /* background-image: url('./assets/images/2023/buttonmenu3-1.png'); */
  position: absolute;
  display: inline;
}

.button-1:hover {
  /* background-image: url('./assets/images/2023/Hoverbuttonmenu2-1.png'); */
  /* display: none; */
}

.button-2 {
  /* background-image: url('./assets/images/2023/buttonmenu3-2.png'); */
  position: absolute;
  display: inline;
}

.button-2:hover {
  /* background-image: url('./assets/images/2023/Hoverbuttonmenu2-2.png'); */
  /* display: none; */
}

.button-3 {
  /* background-image: url('./assets/images/2023/buttonmenu3-3.png'); */
  position: absolute;
  display: inline;
}

.button-3:hover {
  /* background-image: url('./assets/images/2023/Hoverbuttonmenu2-3.png'); */
  /* display: none; */
}

.toshop {
  position: absolute;
  display: inline;
}

.toshop:hover {
  /* display: none; */
}


.content-center {
  text-align: -webkit-center;
}

.home-hidden {
  display: none;
}

.shop-item img.shop-logo {
  width: 90%;
}

.tradition-name {
  position: relative;
  background-image: url('./assets/images/landing/2023/product-name.png');
  width: 75%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  margin-top: 15px;
}


.product-name {
  display: block;
  margin: auto;
  height: 130px;
  background-image: url('./assets/images/landing/2023/product-name.png');
  width: 100%;
  max-width: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 17px;
  padding-top: 15px;
}

.cover-original-has-light {
  position: absolute;
  width: 100%;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 2;
  left: 0;
  top: 0;
}

.cover-original-has-lights {
  position: relative;
  background-image: url('./assets/images/2024/KV2.png');
  width: 100%;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 2;
  left: 0;
  top: 0;
  margin-top: -20px !important;
}

/***** TITLE ****/
.bg-card-text {
  background: url('./assets/images/2024/bg-card-text.png');
  height: 560px;
  padding-top: 4rem !important;
}



.shadow-first-section {
  /* display:none; */
  width: 100%;
  content: '';
  background-image: url('./assets/images/landing/2023/annouce.png');
  background-size: cover;
  background-repeat: round;
}

.shadow-first-section-shop {
  height: 130px;
  padding-top: 40px;
}

.shadow-first-section-shop {
  /* display: none; */
  margin-top: -100px;
  width: 30%;
  content: '';
  background-image: url('./assets/images/landing/2023/annouce.png');
  background-size: cover;
  background-repeat: round;
}

.shadow-first-section-text {
  height: 130px;
  padding-top: 40px;
}

.shadow-first-section-text {
  /* display: none; */
  margin-top: -100px;
  width: 55%;
  content: '';
  background-image: url('./assets/images/landing/2023/annouce.png');
  background-size: cover;
  background-repeat: round;
}

.mg-t {
  margin-top: 0rem;
}

.shadow-first-sections {
  width: 100%;
  content: '';
  margin-top: 2rem;
  background-size: cover;
}

.section-title h1 {
  text-align: center;
  margin-top: -45px
    /* text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5); */
}

.section-title h3 span {
  display: block;
  padding-bottom: 5px;
}

.banner-foreground {
  position: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}

.mongkhol-m {
  display: none;
}

/***** NAV ****/
.nav_list {
  position: relative;
  z-index: 100;
  justify-content: space-around;
  color: #fff !important;
  align-items: center;
}

.toshop {
  /* position: absolute;
  z-index: 999; */
  width: 350px;
}

.toshop-hover {
  /* position: absolute;
  z-index: 999; */
  width: 350px;
}

.shop-center {
  align-items: center;
  justify-content: center;
  text-align: center !important;
  display: list-item;
  justify-content: space-around;
  width: 100%;
  margin-top: -5% !important;
  position: relative;
  z-index: 999 !important;
}

.nav_list a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  /* background-color: #A31D21; */
  height: 100%;
  position: relative;
  /* background-image: url('./assets/images/landing/2023/button-menu.png'); */
  width: 95%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.nav_list a:hover {
  color: #FFDB58;
  /* background: radial-gradient(50% 10368% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);; */
}

.active-link {
  /* background: radial-gradient(50% 10368% at 50% 50%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);; */
  /* color: #ffdb58 !important; */
  color: #ffffff !important;
}

.active-link a {
  /* color: #FFDB58 !important; */
  color: #ffffff !important;
}

.pd-logo {
  padding: 10px;
}

.pd-logo-s {
  padding: 5px;
}

.pd-log-s-s {
  padding: 15px !important;
}

.mx-logo {
  max-width: 170px !important;
}

.btn-menu {
  min-width: 180px;
  height: 50px;
  /* border: 1px solid #ffdb58 !important; */
  box-sizing: border-box;
  border-radius: 10px;
}

/***** PRODUCT ****/
.bg-card {
  position: relative;
  background-image: url('./assets/images/landing/card-bgx.png');
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.product-pc {
  display: block;
}

.product-mobile {
  display: none;
}

/***** SHOP *****/
.wrapper-shop-online {
  background-image: url(./assets/images/landing/2023/shops.png);
  /* background-image: url(./assets/images/landing/2023/coommobile.png); */
  background-size: 100% 100%;
  width: 95% !important;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

.wrapper-shop-text {
  background-image: url(./assets/images/2024/bg-section-text.png);
  /* background-image: url(./assets/images/landing/2023/coommobile.png); */
  background-size: 100% 100%;
  width: 95% !important;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
}

.bg-gold {
  /* background-image: url(./assets/images/landing/2023/bg-gold.png); */
  background-size: 100% 100%;
  background-size: cover;
  margin: 0px 30px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
}

.wrapper-shop-logos img {
  width: auto !important;
}

.shop-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.btn-shop-now {
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  margin-left: -80px;
  justify-content: space-around;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); */
  border-radius: 20px;
  z-index: 2;
  /* background: linear-gradient(180deg, #F73E2A 0%, #9E0100 100%);    color: #fff !important; */
  position: absolute;
  bottom: -20px;
  left: 50%;
}

.shop-item .btn-shop-now,
.shop-item .shop-logo {
  -moz-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  text-decoration: none;
}

.shop-item:hover .btn-shop-now {
  bottom: -15px;
}

.shop-item:hover .shop-logo {
  transform: scale(0.9);
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
}

/***** CAMPAIGN ****/
.wrapper-campaign {
  background: #C73E36 url('./assets/images/landing/bg-pattern1.png') top center repeat;
}

.btn-register {
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: linear-gradient(0deg, #BE4E00 0%, #F3CB96 100%);
  border: 5px solid #F3B46A;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  color: #FFFFFF;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

.btn-register:hover {
  color: #FFFFFF;
  background: linear-gradient(0deg, #F3CB96 0%, #BE4E00 100%);
}

/***** REGISTER ****/
.wrapper-register-form {
  background: rgba(255, 219, 88, 0.3);
  position: relative;
  padding-bottom: 3rem !important;
}

.wrapper-register-form a {
  color: #FFF;
  text-decoration: underline !important;
}

/***** FOOTER ****/
.footer {
  padding-top: 2rem !important;
  background: #E5E5E5;
  width: 100%;
}

.footer-detail {
  border-top: 1px solid #CCC;
  padding-top: 1rem;
}

.wrapper-footer-contact {
  display: flex;
  height: 100px;
}

.wrapper-footer-contact p {
  margin-bottom: 5px;
}

.wrapper-footer-contact p *,
.wrapper-footer-meta p *,
.footer-detail * {
  color: #666 !important;
}

.text-gold {
  color: #FDD8A1 !important;
}

.text-content {
  font-size: 1.7rem !important;
  margin-left: 10%;
  margin-right: 10%;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
}

.thomu {
  /* font-size: 14px !important; */
  font-size: 30px !important;
  margin-left: 10% !important;
  line-height: 50px !important;
  letter-spacing: 0px;
}

.fire {
  margin-top: -70px !important;
  position: absolute;
  z-index: 99;
  width: 87%;
  margin-left: 1%;
}

.fire-text {
  margin-top: -57px !important;
  position: absolute;
  z-index: 99;
  width: 79%;
  margin-left: 5%;
}

.footer_flower {
  position: relative;
  z-index: 100;
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
}

.footer_flower-left {
  float: left !important;
}

.footer_flower-right {
  float: right !important;
}

.footer_line {
  position: relative;
  z-index: 99;
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  bottom: -275px !important;
}

.footer_line-center {
  width: 34%;
  height: 35px !important;
}

.footer_line-left {
  width: 33% !important;
  height: 35px !important;
}

.footer_line-right {
  width: 33% !important;
  height: 35px !important;
}

.wrapper-shop-offline {
  padding-bottom: 0px !important;
}

.fl-left {
  float: left !important;
}

.fl-right {
  float: right !important;
}

.nav_list {
  margin-top: -20px !important;
  margin-top: 0rem;
  margin-bottom: 1rem;
  width: 800px !important;
  height: 100px;
  display: flex;
}

.planMu {
  padding-left: 100px;
  padding-right: 100px;
  line-height: 55px;
}

.mongkhol {
  display: flex;
  padding-left: 40px;
}

.mongkhol-detail {
  line-height: 45px;
}

.pic-mongkhol {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-s3 {
  width: 80% !important;
}

.tradition-img {
  height: 320px;
  vertical-align: bottom;
  display: inline-block;
}

.wrapper-shop-offline {
  /* display: none; */
}

.tradition-detail {
  text-align: left;
}

.tabel-Mu {
  margin-left: auto;
  margin-right: auto;
}

.tabel-Mu>tr>td {
  font-family: Kanit-Light !important;
}

/**** DESKTOP ONLY *****/

@media screen and (min-width: 1776px) {
  .text-head-shop {
    font-size: 30px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 19px !important;
    line-height: 32px !important;
    padding: 30px 20px !important;
  }

  .shadow-first-section {
    height: 570px !important;
    padding-top: 110px !important;
  }
}

@media screen and (min-width: 1575px) and (max-width: 1775px) {
  .text-head-shop {
    font-size: 30px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 16px !important;
    line-height: 32px !important;
    padding: 30px 50px !important;
  }

  .shadow-first-section {
    height: 570px !important;
    padding-top: 110px !important;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1575px) {
  .text-head-shop {
    font-size: 30px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -1.5% !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 50px !important;
  }

  .shadow-first-section {
    height: 570px !important;
    padding-top: 110px !important;
  }
}

@media screen and (min-width: 1415px) and (max-width: 1500px) {

  .text-head-shop {
    font-size: 30px !important;
  }

  .shadow-first-section {
    height: 570px !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .title-des {
    font-size: 20px !important;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -5% !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 50px !important;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1414px) {
  .text-head-shop {
    font-size: 26px !important;
  }

  .shadow-first-section {
    height: 540px !important;
    padding-top: 110px !important;
  }

  .container-hwai {
    max-width: 1300px !important;
  }

  .first-tradition {
    margin-top: -5% !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .title-des {
    font-size: 20px !important;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -8% !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 50px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1349px) {
  .text-head-shop {
    font-size: 26px !important;
  }

  .shadow-first-section {
    height: 520px !important;
    padding-top: 110px !important;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 20px !important;
  }

  .first-tradition {
    margin-top: -5% !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .text-content {
    font-size: 1.5rem !important;
  }

  .title-des {
    font-size: 18px !important;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .cloud {
    width: 15%;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -12% !important;
  }

  .container-hwai {
    max-width: 1300px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 50px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .text-head-shop {
    font-size: 26px !important;
  }

  .shadow-first-section {
    height: 500px !important;
    padding-top: 110px !important;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 20px !important;
  }

  .first-tradition {
    margin-top: -10% !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .text-content {
    font-size: 1.5rem !important;
  }

  .title-des {
    font-size: 18px !important;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .cloud {
    width: 15%;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -16% !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 50px !important;
  }

  .container-hwai {
    max-width: 1200px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }
}

@media screen and (min-width: 1200px) {

  .h-content {
    font-size: 50px !important;
  }

  .detail-2 {
    line-height: 40px !important;
    font-size: 24px !important;
  }

  /* .wrapper-box-3 h5{ */
  /* font-size: 18px !important; */
  /* } */
  .head-1 {
    font-size: 50px !important;
  }

  /* .text-head-shop{
    font-size: 30px !important;
  } */
  .cover-original-has-light {
    height: 880px;
  }

  .cover-original-has-lights {
    height: 1100px !important;
  }

  .logo {
    width: 45%;
    margin-top: 1rem;
  }

  .logo2 {
    width: 35%;
    margin-top: 2rem;
  }

  .wrapper-product h4 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .product-name {
    display: table !important;
  }


  .wrapper-shop-online {
    margin-top: 7rem !important;
  }

  .wrapper-shop-text {
    margin-top: 0rem !important;
    padding-top: 6.5rem !important;
  }

  .shop-item {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    margin-top: 2.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .btn-shop-now {
    width: 160px;
    height: 40px;
    margin-left: -80px;
  }

  /***** CAMPAIGN ****/
  .banner-kv-campaign {
    position: absolute;
    bottom: -80px;
    left: 50%;
    z-index: 2;
    width: 800px;
    margin-left: -400px;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .nav_list {
    width: 550px !important;
  }

  .text-head-shop {
    font-size: 24px !important;
  }

  .shadow-first-section {
    height: 480px !important;
    padding-top: 110px !important;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 20px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -15% !important;
  }

  .first-tradition {
    margin-top: -15% !important;
  }

  .container-hwai {
    max-width: 1100px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 14px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 45px !important;
  }

  .planMu {
    font-size: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 1051px) and (max-width: 1101px) {
  .nav_list {
    width: 550px !important;
  }

  .text-head-shop {
    font-size: 22px !important;
  }

  .shadow-first-section {
    height: 480px !important;
    padding-top: 110px !important;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 20px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -19% !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 1050px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 13px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 45px !important;
  }

  .planMu {
    font-size: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
  .nav_list {
    width: 500px !important;
  }

  .text-head-shop {
    font-size: 20px !important;
  }

  .shadow-first-section {
    height: 480px !important;
    padding-top: 110px !important;
  }

  .mongkhol-detail {
    line-height: 45px !important;
    font-size: 20px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -22% !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 1000px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 12px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 45px !important;
  }

  .planMu {
    font-size: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 800px;
    margin-bottom: -60px;
  }
}

@media screen and (min-width: 950px) and (max-width: 999px) {
  .nav_list {
    width: 500px !important;
  }

  .text-head-shop {
    font-size: 18px !important;
  }

  .mongkhol-detail {
    line-height: 35px !important;
    font-size: 15px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -26% !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 950px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 12px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 45px !important;
  }

  .planMu {
    font-size: 18px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .text-head-shop {
    font-size: 20px !important;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 992px) and (max-width: 999px) {
  .nav_list {
    width: 500px !important;
  }

  .text-head-shop {
    font-size: 18px !important;
  }

  .mongkhol-detail {
    line-height: 50px !important;
    font-size: 16px !important;
  }

  .container-hwai {
    max-width: 950px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 60px !important;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 900px) and (max-width: 949px) {
  .nav_list {
    width: 500px !important;
  }

  .text-head-shop {
    font-size: 18px !important;
  }

  .text-head-shop {
    font-size: 20px !important;
  }

  .mongkhol-detail {
    line-height: 35px !important;
    font-size: 16px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -29% !important;
  }

  .container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 900px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 60px !important;
  }

  .planMu {
    font-size: 18px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 800px;
  }
}

@media screen and (min-width: 850px) and (max-width: 899px) {
  .text-head-shop {
    font-size: 16px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .mongkhol-detail {
    line-height: 30px !important;
    font-size: 16px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -37% !important;
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }

  .toshop {
    width: 280px !important;
  }

  .toshop-hover {
    width: 280px !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 850px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 16px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 60px !important;
  }

  .planMu {
    font-size: 18px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }

  .cover-original-has-lights {
    height: 700px;
  }
}

@media screen and (min-width: 800px) and (max-width: 849px) {
  .cover-original-has-lights {
    height: 700px;
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }

  .text-head-shop {
    font-size: 16px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .mongkhol-detail {
    line-height: 35px !important;
    font-size: 16px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -43% !important;
  }

  .toshop {
    width: 280px !important;
  }

  .toshop-hover {
    width: 280px !important;
  }

  .first-tradition {
    margin-top: -20% !important;
  }

  .container-hwai {
    max-width: 800px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 15px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 60px !important;
  }

  .planMu {
    font-size: 18px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 55px;
  }
}

@media screen and (min-width: 750px) and (max-width: 799px) {
  .cover-original-has-lights {
    height: 800px;
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }

  .text-head-shop {
    font-size: 16px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .mongkhol-detail {
    line-height: 30px !important;
    font-size: 16px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -52% !important;
  }

  .toshop,
  .toshop-hover {
    width: 280px !important;
  }

  .first-tradition {
    margin-top: -50% !important;
  }

  .container-hwai {
    max-width: 750px !important;
  }

  .title-2 {
    font-size: 35px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 14px !important;
    line-height: 32px !important;
    padding: 30px 10px 30px 60px !important;
  }

  .planMu {
    font-size: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 44px;
  }

  .section-mu {
    max-width: 750px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 767px) {
  .cover-original-has-lights {
    height: 800px;
  }

  .text-head-shop {
    font-size: 14px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .footer_flower-left {
    width: 30% !important;
  }

  .footer_flower-right {
    width: 30% !important;
  }

  .footer_line-left {
    height: 20px !important;
  }

  .footer_line-right {
    height: 20px !important;
  }

  .footer_line-center {
    height: 20px !important;
  }

  .footer_line {
    bottom: 24px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 26px !important;
    font-size: 10px !important;
  }

  .mongkhol-detail {
    line-height: 28px !important;
    font-size: 16px !important;
  }

  .first-tradition {
    margin-top: -50% !important;
  }

  .tradition-name {
    width: 50% !important
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }
}


@media screen and (min-width: 750px) and (max-width: 767px) {
  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 100px !important;
    margin-left: -10%;
  }

  .title-1 {
    font-size: 3.4vw !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 749px) {
  .title-1 {
    font-size: 3.4vw !important;
  }

  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 100px !important;
    margin-left: -10%;
  }

  .cover-original-has-lights {
    height: 800px;
    margin-bottom: -100px;
  }

  .text-head-shop {
    font-size: 14px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .first-tradition {
    margin-top: -50% !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -63% !important;
  }

  .toshop,
  .toshop-hover {
    width: 280px !important;
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 699px) {
  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 35px 30px 100px !important;
    margin-left: -10px;
  }

  .cover-original-has-lights {
    height: 700px;
  }

  .text-head-shop {
    font-size: 14px !important;
  }

  .shadow-first-section-shop {
    height: 110px !important;
  }

  .first-tradition {
    margin-top: -50% !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .kv2 {
    margin-top: -80% !important;
  }

  .nav_list {
    height: 75px !important;
    width: 400px !important;
  }

  .toshop,
  .toshop-hover {
    width: 280px !important;
  }
}

/**** MOBILE & TABLET *****/
@media screen and (max-width: 1199px) {
  .cloud {
    width: 15%;
  }

  .fire {
    margin-top: -65px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .title-des {
    font-size: 16px !important;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .text-content {
    font-size: 1.5rem !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .shadow-first-section {
    height: 500px !important;
  }

  .wrapper-shop-online {
    margin-top: 5rem !important;
    margin-bottom: -2 rem;
  }

  .wrapper-shop-online .container {
    padding: 0;
  }

  .shop-item {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    margin: 1rem;
    margin-bottom: 1.5rem;
  }

  .shop-item img.shop-logo {
    width: 90%;
  }

  .btn-shop-now {
    width: 100px;
    height: 30px;
    margin-left: -50px;
  }

  .btn-shop-now img {
    height: 40px;
  }

  .wrapper-shopping-online .section-title h1 {
    font-size: 30px;
  }

  .nav_list a {
    margin-top: -20px !important;
    font-size: 14px !important;
    min-width: 150px !important;
    height: 70% !important;
  }

  .mongkhol {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 700px) {
  .shop-item {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin: 1rem;
    margin-bottom: 1.5rem;
  }

  .shopDesktop {
    display: none;
  }
}


@media screen and (min-width: 320px) and (max-width: 400px) {
  .first-tradition {
    margin-top: -1rem !important;
  }

  .wrapper-shopping {
    margin-bottom: -2rem;
  }
}

/******************** SPECIFIC DEVICE ********************/

/**** MOBILE ONLY *****/
@media screen and (min-width: 320px) and (max-width: 639px) {
  .mobile-h1 {
    display: block;
  }

  .desktop-h1 {
    display: none;
  }

  /* .wrapper-product h4{
   
     font-size: 2.8vw !important;
     font-family: 'Kanit-Light' !important;
     line-height: 15px !important;
  } */


  .offlineShop-m {
    visibility: visible
  }

  .offlineShop-w {
    visibility: hidden
  }


  .wrapper-product h4 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 2.8vw !important;
    font-family: 'Kanit-Light' !important;
    line-height: 15px !important;
  }

  .product-name {
    margin-bottom: 50px;
    padding: 11px !important;
    padding-top: 16px !important;
    padding: 0px 0px;
    display: table !important;
    height: 25%;
    margin-bottom: 40px;
  }

  .mongkhol-m-head {
    margin-top: -20px
  }

  .tradition-table {
    margin-top: -48px;
  }

  .shadow-first-section-shop {
    height: 100px;
  }

  .fire {
    margin-top: -35px !important;
    position: absolute;
    z-index: 99;
    width: 87%;
    margin-left: 1%;
  }

  .fl-left {
    float: left !important;
    width: 65px !important;
  }

  .fl-right {
    float: right !important;
    width: 65px !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }

  .nav_list {
    display: none;
  }

  .sidebar {
    display: block;
  }

  .shadow-first-section-shop {
    width: 65%;
  }

  .thomu {
    font-size: 14px !important;
    margin-left: 0px !important;
    line-height: 30px !important;
    letter-spacing: 0px;
  }

  .wrapper-shop-online {
    background-image: url(./assets/images/landing/2023/com-no-fire.png);
    background-size: 100% 75%;
    width: 100% !important;
    padding-bottom: 20rem;
    background-repeat: no-repeat;
  }

  .wrapper-shop-offline {
    margin-top: -10rem !important;
  }

  .shadow-first-section-shop {
    margin-top: -70px !important;
  }

  .wrapper-shop-text {
    background-image: url(./assets/images/2024/bg-section-text.png);
    background-size: 100% 75%;
    width: 100% !important;
    padding-bottom: 5rem;
    background-repeat: no-repeat;
  }

  .cover-original-has-light {
    height: 280px;
  }

  .shop-item {
    margin: 2.5rem;
  }

  .cloud {
    width: 15%;
  }

  .tradition-name {
    width: 60% !important;
    background-size: 100% 100% !important;
    margin-top: -70px;
  }

  .cover-original-has-lights {
    width: 150% !important;
    max-width: 120% !important;
    overflow: hidden;
    left: -9%;
    height: 300px;
  }


  .logo {
    margin-top: -0.5rem;
    width: 57%;
  }

  .logo2 {
    margin-top: 1.25rem;
    width: 45%;
  }

  .shadow-first-section {
    /* height: 100px; */
    padding-top: 50px;
  }

  .section-title h1 {
    padding-top: 20px;
    font-size: 7vw !important;
  }

  .text-content {
    font-size: 14px !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .section-title h3 {
    font-size: 16px;
    /* padding: 0rem 0px !important; */
  }

  .section-end {
    padding-bottom: 150px !important;
  }

  .light {
    width: 80%;
    margin: 1rem auto;
  }

  .product-pc {
    display: none;
  }

  .product-mobile {
    margin-top: 0rem;
    display: block;
    /* margin-left: 1% !important; */
  }

  .product-name {
    max-width: 190px !important;
    line-height: 0;
  }

  .wrapper-product h4 {
    /* height: 42px; */
    line-height: 20px;
    margin: 10px 0;
    font-size: 14px;
    padding: 0 8px;
    text-decoration: none;

    text-align: center;
    vertical-align: middle;
  }

  .bg-card {
    background-size: auto 100px;
    margin-top: 2rem !important;
  }

  .product-mobile .row>* {
    padding: 0;
  }

  .product-mobile .bg-card:nth-child(2) .wrapper-product:nth-child(2) h4 {
    font-size: 14px;
  }

  .product-mobile .bg-card:nth-child(4) .wrapper-product h4,
  .product-mobile .bg-card:nth-child(6) .wrapper-product:nth-child(1) h4 {
    font-size: 14px;
    margin: 0;
    height: 40px;
  }

  .wrapper-shop-offline h1 {
    font-size: 20px !important;
  }

  .wrapper-shop-logos {
    padding-top: 1rem !important;
  }

  .wrapper-shop-logos div {
    width: 33%;
    display: block;
  }


  .wrapper-shop-logos img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 85% !important;
  }

  .wrapper-campaign-kv {
    padding: 10px;
  }

  .wrapper-campaign-button {
    margin-top: 1.5rem;
  }

  .btn-register {
    height: 60px;
    font-size: 30px;
  }

  .wrapper-campaign {
    padding-bottom: 3rem;
  }

  .wrapper-footer .container {
    padding: 0 1rem !important;
  }

  .wrapper-footer .d-flex {
    display: block !important;
  }

  .wrapper-footer-contact,
  .wrapper-footer-meta {
    margin-top: 1rem;
  }

  .cpf-logo {
    margin-right: 15px;
  }

  .cpf-logo img {
    width: 60px;
  }

  .wrapper-footer-meta,
  .footer-detail {
    text-align: center;
  }

  .wrapper-footer-meta a {
    display: inline-block;
  }

  .footer-detail {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  .footer-detail * {
    font-size: 12px;
  }

  .footer-detail div {
    padding: 5px 0;
  }

  .mg-tt {
    margin-top: 3rem;
  }

  .container-space {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  /***** CONTENT ****/
  .wrapper-box-3 {
    padding-top: 1rem;
  }

  .wrapper-box-3 h5 {
    font-size: 14px;
    line-height: 150%;
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
  }

  /***** CAMPAIGN ****/
  .banner-kv-campaign {
    position: relative;
    top: 150px;
    z-index: 2;
    width: 90%;
  }

  .wrapper-campaign {
    padding: 0 1rem !important;
  }

  .wrapper-campaign .section-title {
    padding-top: 11rem !important;
  }

  .wrapper-campaign h1 {
    font-size: 30px;
  }

  .section-title h1 {
    font-size: 46px;
  }

  .wrapper-campaign .section-title h2 {
    padding-top: 0 !important;
  }

  .wrapper-register-form {
    border-radius: 20px;
    padding: 1rem;
  }

  .form-row {
    margin-bottom: 0.2rem;
  }

  .wrapper-terms {
    margin-top: 5rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-bottom: 5rem !important;
  }

  .wrapper-terms ol,
  .wrapper-terms ul {
    padding-left: 1em;
  }

  .shop-center {
    margin-top: -8% !important;
  }

  .shadow-first-section {
    height: auto !important;
  }

  .footer_flower-left {
    width: 30% !important;
  }

  .footer_flower-right {
    width: 30% !important;
  }

  .footer_line-left {
    height: 20px !important;
  }

  .footer_line-right {
    height: 20px !important;
  }

  .footer_line-center {
    height: 20px !important;
  }

  .footer_line {
    bottom: -60px !important;
  }

  .shop-center {
    margin-top: -20% !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .btn-shop-now img {
    height: 25px !important;
  }

  .shop-item {
    margin: 1.5rem !important;
    width: 70px !important;
    height: 70px !important;
  }

  .title-1 {
    font-size: 18px !important;
    line-height: 150% !important;
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .title-2 {
    font-size: 20px !important;
    line-height: 150% !important;
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .planMu {
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 26px !important;
    font-size: 15px !important;
  }

  .mongkhol-detail {
    font-size: 10px !important;
    line-height: 18px !important;
    padding-top: 3rem !important;
  }

  .mongkhol-m {
    display: flex !important;
    padding-left: 0px !important;
  }

  .mongkhol {
    display: none !important;
    padding-left: 0px !important;
  }

  .pic-mongkhol {
    padding-top: 1rem !important;
  }

  /* .toshop, .toshop-hover {
    width: 200px !important;
  }
  .kv2{
    margin-top: -10% !important;
  } */

}


/**** TABLET ONLY *****/
@media screen and (min-width: 1050px) and (max-width: 1150px) {
  .shadow-first-section {
    height: 480px !important;
  }

  .section-title h1 {
    font-size: 40px !important;
    padding-top: 20px;
  }

  .text-content {
    font-size: 1.4rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }
}

@media screen and (min-width: 940px) and (max-width: 1049px) {
  .w-80 {
    width: 110% !important;
    padding: 10% !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 82% !important;
    margin-left: 1%;
  }

  .section-title h1 {
    font-size: 40px !important;
    padding-top: 20px;
  }

  .text-content {
    font-size: 1.2rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .shadow-first-section {
    height: 470px !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }
}

@media screen and (min-width:900px) and (max-width: 939px) {
  .w-80 {
    width: 110% !important;
    padding: 10% !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 96% !important;
    margin-left: 1%;
  }

  .section-title h1 {
    font-size: 28px !important;
    padding-top: 20px;
  }

  .text-content {
    font-size: 1rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .shadow-first-section {
    height: 450px !important;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }
}

@media screen and (min-width: 741px) and (max-width: 900px) {
  .w-80 {
    width: 110% !important;
    padding: 10% !important;
  }

  .shadow-first-section {
    height: 420px !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 81% !important;
    margin-left: 1%;
  }

  .section-title h1 {
    font-size: 28px !important;
    padding-top: 20px;
  }

  .text-content {
    font-size: 1rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .section-mg {
    position: inherit;
    margin-top: 0% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 740px) {
  .w-80 {
    width: 110% !important;
    padding: 10% !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 80% !important;
    margin-left: 1%;
  }

  .section-title h1 {
    font-size: 28px !important;
    padding-top: 20px;
  }

  .text-content {
    font-size: 1rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }

  .shadow-first-section {
    height: 430px !important;
  }

  .section-mg {
    position: inherit;
    margin-top: -5% !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1199px) {
  .product-pc {
    display: none;
  }

  .product-mobile {
    display: block;
  }

  .logo {
    width: 35%;
    margin-top: 2rem;
  }

  .logo2 {
    width: 35%;
    margin-top: 2rem;
  }

  .cover-original-has-light {
    height: 800px;
  }

  /* .cover-original-has-lights {
    height: 800px;
  } */

  /***** CAMPAIGN ****/
  .banner-kv-campaign {
    position: absolute;
    bottom: -150px;
    left: 50%;
    z-index: 2;
    width: 800px;
    margin-left: -400px;
  }

  .wrapper-campaign {
    padding-top: 11rem;
  }
}

/**** TABLET & DESKTOP *****/
@media screen and (min-width: 640px) {
  .mobile-h1 {
    display: none;
  }

  .offlineShop-m {
    height: 0;
  }

  .desktop-h1 {
    display: block;
  }

  .nav_list {
    margin-top: 0rem;
    margin-bottom: 1rem;
    width: 700px;
    height: 100px;
    display: flex;
  }

  .nav_list a {
    font-size: 16px;
  }

  .shadow-first-section {
    /* height: 420px; */
    padding-top: 110px;
  }

  .section-title h1 {
    font-size: 46px;
    padding-top: 60px;
  }

  .title-1 {
    font-size: 1.9vw !important;
    line-height: 50px !important;
  }

  .title-2 {
    font-size: 2.7vw !important;
    line-height: 50px !important;
  }

  .section-title h3 {
    font-size: 30px;
  }

  .section-title .text-skip-br br {
    display: none;
  }

  .wrapper-campaign {
    padding-bottom: 5rem;
  }

  .wrapper-shop-logos {
    padding-top: 3rem !important;
  }

  .wrapper-campaign-kv {
    margin-top: 3rem !important;
  }

  .wrapper-campaign-button {
    margin-top: 3rem !important;
  }

  .btn-register {
    height: 80px;
    font-size: 40px;
  }

  .cpf-logo {
    margin-right: 20px;
  }

  /***** CONTENT ****/
  .wrapper-box-3 {
    padding-top: 3rem;
  }

  .wrapper-box-3 h5 {
    font-size: 20px;
    line-height: 150%;
    padding: 0 20px;
  }

  /***** CAMPAIGN ****/
  .wrapper-register-form {
    border-radius: 30px;
    padding: 3rem 5rem;
  }

  .form-row {
    margin-bottom: 1.5rem;
  }

  .wrapper-terms {
    margin-top: 8rem;
  }

}

@media screen and (min-width: 640px) and (max-width: 800px) {
  .title-1 {
    font-size: 18px !important;
    line-height: 50px !important;
  }

  .title-2 {
    font-size: 22px !important;
    line-height: 50px !important;
  }
}

.h-content {
  font-size: 4vw !important;
}


@media screen and (min-width: 501px) and (max-width: 639px) {
  .wrapper-box-3 h5 {
    font-size: 18px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 100px !important;
    margin-left: -10%;
  }

  .shadow-first-section {
    height: 390px !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 79% !important;
    margin-left: 1%;
  }

  .text-head-shop {
    font-size: 16px !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .kv2 {
    margin-top: 0% !important;
  }

  .shop-center {
    margin-top: -8% !important;
  }
}

@media screen and (min-width: 450px) and (max-width: 500px) {
  .wrapper-box-3 h5 {
    font-size: 16px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 85px !important;
    margin-left: -20px !important;
  }

  .shadow-first-section {
    height: 370px !important;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 78% !important;
    margin-left: 1%;
  }

  .text-head-shop {
    font-size: 14px !important;
  }

  .wrapper-shop-offline h1 {
    font-size: 16px !important;
  }

  .text-content {
    font-size: 3.4vw !important;
    line-height: 5vw !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .text-head-shop {
    font-size: 16px !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .kv2 {
    margin-top: -5% !important;
  }

  .shop-center {
    margin-top: -4% !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 449px) {
  .wrapper-box-3 h5 {
    font-size: 16px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 60px !important;
    margin-left: -3%;
  }

  .wrapper-shopping {
    margin-bottom: -2rem;
  }

  .titile-mu {
    font-size: 18px !important;
  }

  .shadow-first-section {
    height: 340px !important;
  }

  .shadow-first-section {
    padding-top: 34px;
  }

  .section-title h1 {
    /* font-size: 15px !important; */
  }

  .h-content {
    font-size: 1.2rem !important;
  }

  .text-content {
    font-size: 3.4vw !important;
    line-height: 5vw !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 83% !important;
    margin-left: -3%;
  }

  #head_menu1 {
    font-size: 6vw !important;
  }

  #head_menu2 {
    font-size: 6vw !important;
  }

  .wrapper-shop-offline h1 {
    font-size: 16px !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .kv2 {
    margin-top: -8% !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .text-head-shop {
    font-size: 3.6vw !important;
  }
}


@media screen and (min-width: 350px) and (max-width: 399px) {

  .shadow-first-section {
    height: 330px !important;
  }

  .wrapper-box-3 h5 {
    font-size: 16px !important;
    line-height: 32px !important;
    padding: 30px 30px 30px 60px !important;
    margin-left: 0%;
  }

  .text-content {
    font-size: 3.8vw !important;
    line-height: 5vw !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .shadow-first-section {
    padding-top: 38px;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 86% !important;
    margin-left: -5%;
  }

  .wrapper-shop-offline h1 {
    font-size: 12px !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .kv2 {
    margin-top: -14% !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .text-head-shop {
    font-size: 13px !important;
  }

  .container-space {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .h-content {
    font-size: 1.2rem !important;
  }

  #head_menu1 {
    font-size: 6vw !important;
  }

  #head_menu2 {
    font-size: 6vw !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {

  .wrapper-box-3 h5 {
    font-size: 11px !important;
    line-height: 20px !important;
    padding: 30px 20px 30px 43px !important;
  }

  .shadow-first-section {
    height: 360px !important;
  }

  .text-content {
    font-size: 10px !important;
    margin-left: 5%;
    margin-right: 5%;
  }

  .fire {
    margin-top: -55px !important;
    position: absolute;
    z-index: 99;
    width: 71% !important;
    margin-left: 1%;
  }

  .wrapper-shop-offline h1 {
    font-size: 12px !important;
  }

  .toshop,
  .toshop-hover {
    width: 200px !important;
  }

  .kv2 {
    margin-top: -26% !important;
  }

  .text-head-shop {
    font-size: 14px !important;
  }

  .shop-center {
    margin-top: -5% !important;
  }

  .h-content {
    font-size: 5vw !important;
  }

  #head_menu1 {
    font-size: 6vw !important;
  }

  #head_menu2 {
    font-size: 6vw !important;
  }
}

@media screen and (min-width: 450px) and (max-width: 700px) {
  #head_menu1 {
    font-size: 6vw !important;
  }

  #head_menu2 {
    font-size: 6vw !important;
  }
}


/**** SPECIAL *****/

@media screen and (min-width:700px) and (max-width:800px) {
  .first-tradition {
    margin-top: -40% !important;
  }
}

@media screen and (min-width: 1000px) {
  .wrapper-shop-logos img {
    height: 60px;
  }

  .first-tradition {
    margin-top: -10% !important;
  }

}

@media screen and (min-width: 1200px) {
  .wrapper-shop-logos img {
    height: 65px;
  }

  .first-tradition {
    margin-top: 0% !important;
  }

  .cover-original-has-lights {
    margin-bottom: -20rem;

  }
}

@media screen and (min-width: 1300px) {
  .wrapper-shop-logos img {
    height: 75px;
  }
}

@media screen and (min-width: 1400px) {
  .wrapper-shop-logos img {
    height: 75px;
  }

  .first-tradition {
    margin-top: 5% !important;
  }

  .cover-original-has-lights {
    margin-bottom: -15vw !important
  }
}

@media screen and (min-width: 1600px) {
  .cover-original-has-lights {
    margin-bottom: -10vw !important
  }
}

@media screen and (min-width: 1800px) {
  .cover-original-has-lights {
    margin-bottom: -5vw !important
  }
}

.mongkhol-m>.div-mongkhol-detail>h3 {
  font-size: 15px !important;
  padding-top: 2rem !important;
}

.mongkhol-m>.div-mongkhol-detail>h3>font {
  text-align: center;
}


.carousel-control-prev,
.carousel-control-next {
  z-index: 9 !important;

}

.carousel-slide {
  max-width: 100%;
}

.carousel-caption {
  top: 1px
}

.carousel-item {
  width: 100% !important
}

.nav_list_home {
  margin-top: 12% !important;
}

.slide-mobile {
  display: none;
}

/* slider  */

@media screen and (min-width: 350px) and (max-width: 650px) {

  #head_tradition {
    padding-top: 45px !important;
  }

  .wrapper-shop-offline {
    margin-top: -10rem !important;
  }

  .cloud-left {
    width: 20% !important;
    margin-left: 7% !important;
  }

  .cloud {
    margin-top: -30px;
  }

  .cloud-right {
    width: 20% !important;
    margin-right: 7% !important;
  }


  .shadow-first-section-text {
    width: 100%;
    /* position: absolute; */
    height: 100px;
    margin-top: -20px !important;
  }

  .footer_line {
    bottom: -100px !important;
  }

  .footer_flower-top {
    padding-top: 260px !important;
  }

  .bg-gold {
    margin: 0px 10px !important;
  }

  .bg-card-text {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .underline-shadow-first-section-text {
    margin-top: 0px !important;
  }

  .underline-shadow-first-section-texts>h3 {
    padding: 0px !important;
  }

  .fire-text {
    margin-top: 15px !important;
    width: 97% !important;
    margin-left: -5% !important;
  }

  .underline-shadow-first-section-texts> {
    margin: 0px !important;
    padding: 0px !important;
  }

  .bg-card-text {
    height: 315px;
  }

  #head_menu1 {
    margin-top: 3rem !important;
  }

  #head_menu2 {
    margin-top: 1rem !important;
  }

  .logo {
    margin-top: 0.5rem;
    width: 57%;
  }

  .slide-pc {
    display: none;
  }

  .slide-mobile {
    max-width: 100%;
    overflow: hidden;
    display: block;
  }

  .slide-mobile .carousel-inner {
    width: 120%;
    right: 10%;
  }


}