.input-data {
    height: 50px !important;   
    width: 100% !important;
    /* margin-left: 2rem; */
    outline: none !important;
    border: none !important;
    background: #FFFFFF;
    box-shadow: inset 3px 3px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding-left: .75rem;
    position:relative;
}

.wrapper-input-data {
    position: relative;
}

.input-data-svg {
    position: absolute !important;
    top: 16px;
    right: 14px;
    z-index: 1;
}

.btn-regis {
    max-width: 400px;
    width: 100%;
    background: linear-gradient(0deg, #BE4E00 0%, #F3CB96 100%);
    border: 5px solid #F3B46A;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    z-index: 10;
    margin: 0 auto;
}

.btn-regis-disabled {
    max-width: 400px;
    width: 100%;
    background: linear-gradient(0deg, #BE4E00 0%, #ffffff 100%);
    border: 5px solid #F3B46A !important;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 50%);
    border-radius: 40px;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    z-index: 10;
    margin: 0 auto;
}


.btn-regis:hover {
    color: #F3B46A;
}

.wrapper-campaign {
    padding-top: 8rem;
}

@media (max-width: 991.98px) {
    .btn-regis, .btn-regis-disabled {
        width: 75%;
        font-size: 20px !important;
    }
    .wrapper-campaign {
        padding-top: 4rem;
    }
}



.wrapper-register {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
}
ul li {
    word-wrap: break-word !important;
}

.customize-select-field {
    position: relative;
}

